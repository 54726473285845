.exp-icon {
    color: #ffffff9c;
}

.heading {
    font-size: 40pt;
}

.skills {
    text-align: center;
}

.service-card {
    background-color: #ffffff18;
    border: 1px #ffac54 solid;
    padding: 20px;
    z-index: 100;
    position: relative;
    margin-bottom: 60px;
}

.service-card h2 {
    margin-bottom: 60px;
    font-weight: 700;
}

.service-card p {
    font-size: 16pt;
}

.skill-svg {
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    display: flex;
}

.skill-top {
    padding-top: 5vh;
    padding-bottom: 5vh;
}
