body,
html,
.app {
  color           : white;
  margin          : 0;
  background-color: black;
  cursor          : none;
  overflow      : hidden !important;
  height: 100%;
}

#react-root {
  position: relative;
  z-index : 50;
  height: 100%;
}

#canvas-ogl {
  position        : fixed;
  left            : 0px;
  top             : 0px;
  width           : 100%;
  height          : 100%;
  background-color: none;
  z-index         : 2;
}

.non-landing {
  z-index: 5;
  position: relative;
}

.content {
  height: 100%;
}

.center {
  height         : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  text-align: center;
}

.full {
  width: 100%;
  height: 100%;
}

::-moz-selection {
  color     : black;
  background: #ffac54;
}

::selection {
  color     : black;
  background: #ffac54;
}
