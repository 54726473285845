.highlight {
    font-weight: 700 !important;
}

.big {
    position             : absolute;
    top                  : -70vh;
    font-size            : 150vh;
    color                : rgba(255, 255, 255, 0.075);
    user-select          : none;
    -webkit-touch-callout: none;
    -webkit-user-select  : none;
    -khtml-user-select   : none;
    -moz-user-select     : none;
    -ms-user-select      : none;
    z-index: 10;
}

.landing {
    height: 100%;
    position: relative;
    z-index: 200;
}

.landing h2 {
    color: #ffac54;
    margin-top: 50px;
}

.icon-container:hover {
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #ffac54;
    color: black;
    text-decoration: none;
}

.icon-container {
    z-index: 5000;
    color:white;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    flex-grow: 1;
    max-width: 160px;
}

.links {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

a {
    text-decoration: none;
    cursor: none;
}

a:hover {
    text-decoration: none;
}

.icon {
    margin-bottom: 20px;
}


.inline {
    margin-left: 500px;
}

.S {
    left: -15vw;
}

.A {
    right: -20vw;
}

.slogan {
    color        : white;
    transition   : 2s ease-in;
    margin-top   : 40vh;
    font-size    : 30pt;
    margin-bottom: 0;
    position     : relative;
    font-family  : "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media (orientation: portrait) {
    .slogan {
        margin-top: 20vh;
    }
  }

.blink {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        translate: 0 12px;
    }
}
