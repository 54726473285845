.inverted {
    -moz-transform   : scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform     : scale(1, -1);
    -ms-transform    : scale(1, -1);
    transform        : scale(1, -1);

    right     : -20vw;
    margin-top: 14vw;
}

#canvas-ogl-eng {
    position        : fixed;
    left            : 0px;
    top             : 0px;
    width           : 100%;
    height          : 100%;
    background-color: none;
    z-index         : 2;
}


.engineer {
    border-top: 3px #ffac54 solid;
    border-bottom: 3px #ffac54 solid;
    background-color: black ;
    box-shadow: 0px 0px 75px black;
}

.engineer h2 {
    font-weight: 600;
}

.left {
    align-items: flex-start !important;
}

.se-mobile {
    color    : #ffac54;
    font-size: 17vw !important;
}

.gear {
    z-index: 10;
    color           : #131313 !important;
    position        : absolute !important;
    font-size       : 330px !important;
    transform-origin: 50% 61% !important;
}

#gear1 {
    left: -100px !important;
    top : -40px !important;
}

#gear2 {
    left: 20px !important;
    top : 250px !important;
}

#gear3 {
    left: -170px !important;
    top : 498px !important;
}



@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform        : rotate(360deg);
    }
}

@-moz-keyframes spin-back {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin-back {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spin-back {
    100% {
        -webkit-transform: rotate(-360deg);
        transform        : rotate(-360deg);
    }
}